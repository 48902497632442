import React from "react"
// import { navigate } from "gatsby-link"
import { globalHistory } from '@reach/router'
import styled from 'styled-components'
import tachyons from 'tachyons-components'

const FieldContainer = tachyons('div')`
w-100 w-30-l dib pr4-l v-top
`
const ButtonContainer = tachyons('div')`
w-100 w-30-l dib v-top
`
const Label = tachyons(`label`)`
dib f6
`
const StyledLabel = styled(Label)`
  font-weight: 400;
  color: var(--textDarkMode);
  line-height: 1.5;
`
const Input = tachyons(`input`)`
pa2 input-reset mb3 mb0-l br2 db w-100
`
const StyledInput = styled(Input)`
background-color: var(--bgAccent);
color: var(--textPrimary);
border: 1px solid var(--buttonBorder);
background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.09), hsla(0, 0%, 100%, 0.05));
box-shadow: 0 2px 4px 0 var(--buttonBoxShadow);
`
const Button = tachyons(`button`)`
button w-100 w-auto-l no-underline bn br2 ph4 pv3 dib bg-primary button-primary tc fw5 pointer ml3-l mt3 mt2-l
`
const StyledButton = styled(Button)`
@media screen and (min-width: 30em) {
  margin-top: 8px;
}
`
const NewsletterContainer = tachyons('div')`
wrap-nav
`
const NewsletterTitle = tachyons('h3')`
f3 mb2 fw8
`
const StyledNewsletterTitle = styled(NewsletterTitle)`
letter-spacing: -1.1px;
`
const NewsletterDesc = tachyons('p')`
mb4 lh-copy
`
const NewsletterHighlight = tachyons('div')`
bg-near-white pa3 highlight lh-copy
`
const StyledNewsletterHighlight = styled(NewsletterHighlight)`
padding-top: 28px !important;
padding-bottom: 28px !important;
`
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const Contact = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, fname: "", email: "", cta: "", feedbackMsg: null };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch(`${globalHistory.location.pathname}`, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(response =>
        this.setState({
          feedbackMsg: "Form submitted successfully!",
        })
      )
      .catch(error =>
        alert(error)
      );
  };

  render() {
    const { fname, email, feedbackMsg } = this.state;
    return (
        <NewsletterContainer>
        <div className="hr-top mt5 mb5"></div>
        <StyledNewsletterTitle>
          Get new updates in your inbox
        </StyledNewsletterTitle>
        <NewsletterDesc>I'll send you fresh write-ups along with links to digital product & design related content.</NewsletterDesc>
        <form
          name="newsletter"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={this.handleSubmit}
        >
          {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
          <input type="hidden" name="form-name" value="newsletter" />
          <div hidden>
            <label>
              Don’t fill this out:{" "}
              <input name="bot-field" onChange={this.handleChange} />
            </label>
          </div>
          {feedbackMsg ? (
          <StyledNewsletterHighlight>
            Awh thanks, I truly appreciate your interest! I'll keep you posted <span role="img" aria-label="emoji">🙏🏻</span>
          </StyledNewsletterHighlight>
          ) : (
          <div>
            <FieldContainer>
            <StyledLabel htmlFor={"fname"} >First name (optional)</StyledLabel>
            <StyledInput type={"text"} value={fname} name={"fname"} onChange={this.handleChange} id={"fname"} placeholder={"Your first name"}/>
            </FieldContainer>
            <FieldContainer>
            <StyledLabel htmlFor={"email"}>Email</StyledLabel>
            <StyledInput type={"email"} value={email} name={"email"} onChange={this.handleChange} id={"email"} required={true} placeholder={"Your email address"}/>
            </FieldContainer>
            <ButtonContainer>
            <StyledButton type="submit">Subscribe →</StyledButton>
            </ButtonContainer>
          </div>
          )}
        </form>
        </NewsletterContainer>
    );
  }
}

export default Contact
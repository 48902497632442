import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Tilt from 'react-parallax-tilt'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import styled from 'styled-components'
import tachyons from 'tachyons-components'
import Layout from '../components/Layout'
import Grab from "../assets/grab.svg"
import Newsletter from '../components/Newsletter'
import { globalHistory } from '@reach/router'
import Content, {HTMLContent} from '../components/Content'
import { format } from 'date-fns'
import GlobalStyle from "../styles/GlobalStyle"

const ArticleContainer = tachyons('article')`
pv4-ns
`
const TitleWrapper = tachyons('div')`
tc hr-bottom hero-bg ph3 ph4-ns
`
const ArticleTitle = tachyons('h1')`
f3 f2-ns fw6 tc tl-l db pt5 pt6-ns mt0 mb2 lh-title
`
const ContentPadding = tachyons('div')`
ph3 ph4-ns
`
const ContentContainer = tachyons('div')`
mt4 mt5-l mw12
`
const ContentBody = tachyons('div')`
center measure-wide lh-copy mb5
`
const TopPrevNextContainer = tachyons('div')`
dt w-100 border-box mt3 pb2 hr-bottom tl ph3 ph4-ns
`
const PrevNextContainer = tachyons('div')`
dt w-100 border-box wrap-nav tl pt3
`
const PrevNextWrapper = tachyons('div')`
wrap-nav
`
const NextContainer = tachyons('div')`
dib w-100 w-50-l tr-l fn fr-l
`
const PrevContainer = tachyons('div')`
dib w-100 w-50-l tl fn fl-l
`
const StyledLink = tachyons(Link)`
no-underline link-header lh-copy
`
const LinkHeader = tachyons('h4')`
mb2 mt0 link-header
`
const PaginateHeader = tachyons('p')`
mb1 light-silver fw4 f5 small 
`
const TitleHeaderContainer = tachyons('div')`
cf wrap-nav
`
const TitleHeader = tachyons('div')`
fl w-100 w-60-l v-bottom tl
`
const TitleMeta = tachyons('h4')`
f5 mt0 mb3 tc tl-l lh-solid
`
const StyledTitleMeta = styled(TitleMeta)`
color: var(--textSecondary);
font-weight: var(--fontNormal);
`
const StyledGrab = styled(Grab)`
  width: 40px;
  vertical-align: bottom;
`
const TitleTLDR = tachyons('p')`
lh-copy mt3 mb2 tc tl-l mw6 center fl-l
`
const ImageContainer = tachyons('div')`
fl w-100 w-40-l tc v-bottom
`
const StyledImage = tachyons('div')`
center mw6
`

export const ProjectPostTemplate = ({
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content
  return (
    <ContentContainer>
      <GlobalStyle/>
      <ContentBody>
        <PostContent content={content} />
      </ContentBody>
    </ContentContainer>
  )
}

ProjectPostTemplate.propTypes = {
  content: PropTypes.node,
  contentComponent: PropTypes.func,
}


const ProjectPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { allMarkdownRemark: posts } = data
  const postIndex = posts.edges.map(p => p.node.id).indexOf(post.id)
  const nextPost = posts.edges[postIndex + 1]
  const prevPost = posts.edges[postIndex - 1]
  const frontmatter = post.frontmatter
  const title = frontmatter.title
  const date = frontmatter.date
  const description = frontmatter.description
  const image = frontmatter.featuredImage.image
  const domain = data.site.siteMetadata.siteUrl

  return (
    <Layout contentComponent={HTMLContent}>
      <ArticleContainer>
        <Helmet
          titleTemplate="%s"
          htmlAttributes={{
            class: "project"
          }}
        >
          <title>{`${title} · Ajmal Afif`}</title>
          <meta name="description" content={`${description}`} />
          <meta
            name="image"
            content={`${domain}${image.childImageSharp.fluid.src}`}
          />

          <meta name="twitter:title" content={`${title} · Ajmal Afif`} />
          <meta name="twitter:description" content={description} />
          <meta
            name="twitter:image"
            content={`${domain}${image.childImageSharp.fluid.src}`}
          />
          <meta name="twitter:card" content="summary" />

          <meta property="og:description" content={description} />
          <meta property="og:title" content={`${title} · Ajmal Afif`} />
          <meta
            property="og:image"
            content={`${domain}${image.childImageSharp.fluid.src}`}
          />
          <meta property="og:type" content="article" />

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Article",
              mainEntityOfPage: `${domain}`,
              url: `${domain}${globalHistory.location.pathname}`,
              datePublished: `${date}`,
              dateModified: Date(),
              headline: `${title}`,
              name: `${title}`,
              description: `${description}`,
              image: {
                "@type": "ImageObject",
                url: `${domain}${image.childImageSharp.fluid.src}`
              },
              author: {
                "@type": "Person",
                name: "Ajmal Afif"
              },
              copyrightHolder: {
                "@type": "Person",
                name: "Ajmal Afif"
              },
              copyrightYear: `${format(new Date(), "yyyy")}`,
              creator: {
                "@type": "Person",
                name: "Ajmal Afif"
              },
              publisher: {
                "@type": "Organization",
                name: "Ajmal Afif",
                logo: {
                  "@type": "ImageObject",
                  url: `${domain}/img/og-image-square.png`
                }
              }
            })}
          </script>
        </Helmet>
        <TitleWrapper>
          <TitleHeaderContainer>
            <TitleHeader>
              <ArticleTitle>{title}</ArticleTitle>
              <StyledTitleMeta>
                <StyledGrab/> · {post.frontmatter.type}{" "}
                {format(new Date(date), "MMMM yyyy")}
              </StyledTitleMeta>
              <TitleTLDR>{description}</TitleTLDR>
            </TitleHeader>
            <ImageContainer>
              <StyledImage>
                <Tilt
                  tiltReverse={true}
                  tiltMaxAngleX="0.1"
                  tiltMaxAngleY="2"
                  gyroscope={true}
                  scale="1.01"
                >
                  <PreviewCompatibleImage
                    imageInfo={post.frontmatter.featuredImage}
                  />
                </Tilt>
              </StyledImage>
            </ImageContainer>
          </TitleHeaderContainer>
        </TitleWrapper>
        <TopPrevNextContainer>
          <PrevNextWrapper>
            {prevPost && (
              <PrevContainer>
                <PaginateHeader>← Recent</PaginateHeader>
                <LinkHeader>
                  <StyledLink to={`${prevPost.node.fields.slug}`}>
                    {prevPost.node.frontmatter.title}
                  </StyledLink>
                </LinkHeader>
              </PrevContainer>
            )}
            {nextPost && (
              <NextContainer>
                <PaginateHeader>Older →</PaginateHeader>
                <LinkHeader>
                  <StyledLink to={`${nextPost.node.fields.slug}`}>
                    {nextPost.node.frontmatter.title}
                  </StyledLink>
                </LinkHeader>
              </NextContainer>
            )}
          </PrevNextWrapper>
        </TopPrevNextContainer>
        <ContentPadding>
          <ProjectPostTemplate
          content={post.html}
          contentComponent={HTMLContent}
          />
          <PrevNextContainer>
            {prevPost && (
              <PrevContainer>
                <PaginateHeader>← Recent</PaginateHeader>
                <LinkHeader>
                  <StyledLink to={`${prevPost.node.fields.slug}`}>
                    {prevPost.node.frontmatter.title}
                  </StyledLink>
                </LinkHeader>
              </PrevContainer>
            )}
            {nextPost && (
              <NextContainer>
                <PaginateHeader>Older →</PaginateHeader>
                <LinkHeader>
                  <StyledLink to={`${nextPost.node.fields.slug}`}>
                    {nextPost.node.frontmatter.title}
                  </StyledLink>
                </LinkHeader>
              </NextContainer>
            )}
          </PrevNextContainer>
          <Newsletter />
        </ContentPadding>
      </ArticleContainer>
    </Layout>
  )
}

ProjectPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ProjectPost

export const pageQuery = graphql`
  query ProjectPostTemplateByID($id: String!) {
     site {
      siteMetadata {
        siteUrl
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD")
        title
        description
        tags
        type
        featuredImage {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 640, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
            extension
            publicURL
          }
        }
      }
    }
    allMarkdownRemark(
      sort: {
        order: DESC,
        fields: [frontmatter___date]
      },
      filter: {
        frontmatter: {
          templateKey: {
            eq: "project-post"
          }
        }
      })
    {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
